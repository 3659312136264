import * as React from "react";
import ApyContextProvider from "/src/components/Context/ApyContext";
import PricesContextProvider from "/src/components/Context/PriceContext";

import AOS from 'aos';
import 'rsuite/dist/rsuite.min.css';
import 'react-vertical-timeline-component/style.min.css';
import 'aos/dist/aos.css';

AOS.init({
    once: true
});

export const shouldUpdateScroll = ({
                                  routerProps: { location },
                                  getSavedScrollPosition,
                              }) => {
    const { pathname } = location
    // list of routes for the scroll-to-top-hook
    const scrollToTopRoutes = [
        `/wallet/`,
        `/wealth/`,
        `/bank/`,
        `/token/`,
        `/partner/`,
        `/supported/`,
        '/legal/privacy-policy',
        '/legal/terms-of-service']

    // if the new route is part of the list above, scroll to top (0, 0)
    if (scrollToTopRoutes.indexOf(pathname) !== -1) {
        window.scrollTo(0,0)
    }

    return false
}

export const onServiceWorkerInstalled = ({
                                              apiCallbackContext,
                                              pluginOptions,
                                   }) => {
   console.log('Service worker started')
}

export const wrapRootElement = ({ element }) => (
    <ApyContextProvider>
        <PricesContextProvider>
            {element}
        </PricesContextProvider>
    </ApyContextProvider>
)
